<template>
  <div class="">
    <div class="flex justify-between items-center mb-6 border-b pb-6">
      <div>
        <h1 class="text-4xl">New Reconcilation</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <form class="bg-white shadow rounded-md p-5" @submit.prevent="onSubmit">
        <h1 class="px-3 text-2xl font-bold mb-4">Reconcilation Detailes</h1>
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Date
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="loadData()"
                readonly
                :value="form.date"
                type="date"
                :class="{
                  'is-invalid': form.errors.has('date'),
                }"
                required
              />
            </div>
          </div>
        </div>
        <div class="w-full text-center my-3">
          <p class="text-red-600" v-if="showTopError">{{ topErrorMessage }}</p>
        </div>
        <hr />

        <template>
          <div class="flex flex-wrap overflow-hidden">
            <div class="w-full overflow-hidden xl:my-3 xl:px-3">
              <!-- Column Content -->
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Bank
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Slip Number
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Total Deposited
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Referance Number
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only"></span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="cashcollections"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr v-for="(collection, i) in this.form.collections" :key="i">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ collection.bank }}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ collection.bank_slip_number }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      GMD <b>{{ formatPrice(collection.total_deposited) }}</b>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <input
                        type="text"
                        class="w-full p-2"
                        v-model="collection.referance_number"
                        placeholder="Enter bank reference number"
                      />
                    </td>

                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                      "
                    >
                      <span v-if="collection.referance_number.length > 1"
                        ><i
                          class="bx bxs-check-circle text-2xl text-green-600"
                        ></i
                      ></span>
                    </td>

                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                        flex
                        justify-end
                        items-center
                      "
                    >
                      <div class="">
                        <button
                          v-if="!collection.isReconcile"
                          type="button"
                          @click="saveReconcile(collection)"
                          class="
                            mx-4
                            py-2
                            px-4
                            rounded-full
                            text-xs text-center
                            bg-green-400
                            hover:bg-green-300
                            text-green-900
                            hover:text-green-900
                          "
                        >
                          Save
                        </button>
                        <button
                          v-else
                          @click="saveReconcile(collection)"
                          type="button"
                          class="
                            mx-4
                            py-2
                            px-4
                            rounded-full
                            text-xs text-center
                            bg-green-400
                            hover:bg-green-300
                            text-green-900
                            hover:text-green-900
                          "
                        >
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>

                  <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
          <!-- Enter comment -->
          <div class="flex-wrap overflow-hidden">
            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Enter comment
                </label>
                <textarea
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="form.remarks"
                  required
                  minlength="5"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
              <button
                @click="showConfirmModal()"
                class="
                  px-4
                  py-2
                  rounded
                  text-white
                  inline-block
                  shadow-lg
                  bg-blue-500
                  hover:bg-blue-600
                  focus:bg-blue-700
                "
                :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                type="button"
                :disabled="isLoading"
              >
                <span v-if="isLoading">Loading...</span>
                <span v-else>Save</span>
              </button>
            </div>
          </div>

          <!-- End Enter comment -->
        </template>
      </form>

      <confimation
        v-show="isConfirmModalVisible"
        modalHeadline="Please confirm?"
        :isLoading="isLoading"
        :confirmMessage="`Are you sure this action cannot be undone`"
        @confirmEvent="confirmRequest"
        @close="closeConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";
import Confimation from "@/components/Confimation.vue";

export default {
  middleware: "auth",

  layout: "default",
  components: { Confimation },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    isLoading: false,
    cashcollections: null,

    form: new Form({
      id: null,
      date: null,

      bank: "VISTA BANK",
      collections: [],
      remarks: "",
    }),

    branchesSales: null,
    showTopError: false,
    topErrorMessage: "",

    isConfirmModalVisible: false,
    userHasConfirmed: false,
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "reconciliations-create")) {
      this.$router.go(-1);
      return false;
    }
  },

  mounted() {
    this.loadData(this.$route.params.id);
  },

  methods: {
    async loadData(id) {
      this.showTopError = false;

      try {
        const { data } = await axios.get(`/reconciliation/${id}`);

        console.log("Reconcilation", data);

        this.cashcollections = data.data.collection_reconciles;
        this.form.id = data.data.id;
        this.form.date = data.data.date;
        this.form.remarks = data.data.remarks;

        this.cashcollections.forEach((item, index) => {
          console.log(index);

          this.form.collections.push({
            id: item.id,
            bank: item.bank,
            isReconcile: item.isReconcile,
            bank_slip_number: item.bank_slip_number,
            total_deposited: item.total_deposited,
            referance_number:
              item.bank_reference == null ? "" : item.bank_reference,
          });
        });

        return true;
      } catch (e) {
        console.log("Error");

        return false;
      }
    },

    async saveReconcile(collection) {
      // Submit the form
      this.isLoading = true;

      console.log("Save Reconcile", collection);

      try {
        await axios.patch("/reconciliation/save/collection", collection);

        this.isLoading = false;
        // this.loadData(this.$route.params.id);
        //   this.$router.push({ path: "/reconciliations" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    async onSubmit() {
      // Submit the form
      if(this.isLoading) return
      this.isLoading = true;

      try {
        await this.form.patch("/reconciliation/complete");

        this.isLoading = false;

        this.$router.push({ path: `/reconciliations` });
        this.closeConfirmModal();

        return true;
      } catch (e) {
        this.isLoading = false;
        this.closeConfirmModal();

        e.response.data.data.forEach((item, index) => {
          console.log(index);
          console.log(item.bank);

          this.form.collections.push({
            id: item.id,
            bank: item.bank,
            isReconcile: item.isReconcile,
            bank_slip_number: item.bank_slip_number,
            total_deposited: item.total_deposited,
            referance_number:
              item.bank_reference == null ? "" : item.bank_reference,
          });
        });

        if (e.response.status == 400) {
          Swal.fire({
            type: "warning",
            title: "Oops...",
            text: `${e.response.data.error}`,
            reverseButtons: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
          });
        }

        console.log("Error", e.response.status);
        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },

    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        this.onSubmit();
      }
    },
  },
};
</script>
